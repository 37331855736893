<template>
  <div>
    <tabla></tabla>
  </div>
</template>

<script>
import Tabla from './tabla/Index.vue'

export default {
  components: {
    Tabla,
  },
  setup() {
    return {}
  },
}
</script>
