<template>
  <div>
    <v-data-table
      dense
      :headers="headers"
      :items="lista"
      :loading="cargandoTabla"
      class="elevation-1"
      :items-per-page="registrosPorPaginaValue"
      hide-default-footer
    >
      <template v-slot:top>
        <v-card>
          <v-card-title class="align-start">
            <span class="font-weight-semibold">{{ tituloTabla }}</span>
            <v-spacer></v-spacer>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :loading="cargando"
                  small
                  color="accent"
                  v-bind="attrs"
                  v-on="on"
                  @click="dialogSearch = true"
                >
                  <v-icon color="primary">
                    {{ icons.mdiFilter }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Filtrar</span>
            </v-tooltip>
            <v-dialog
              v-model="dialogSearch"
              scrollable
              max-width="700px"
              persistent
            >
              <v-card>
                <v-card-title>
                  Buscar Programas
                  <v-spacer></v-spacer>
                  <v-icon>{{ icons.mdiFilter }}</v-icon>
                </v-card-title>
                <v-card-text
                  class="py-5"
                  style="height: 300px;"
                >
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="objSearch.listfiles"
                        :items="filterFiles"
                        item-value="id"
                        item-text="name"
                        outlined
                        dense
                        chips
                        small-chips
                        label="Filtrar por"
                        multiple
                        hide-details
                      >
                        <template v-slot:selection="data">
                          <v-chip
                            class="elevation-2 my-1"
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            @click="data.select"
                            @click:close="remove(data.item)"
                          >
                            {{ data.item.name }}
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </v-col>

                    <!-- Nombre -->
                    <v-col
                      v-if="isNameSelected"
                      cols="12"
                    >
                      <v-text-field
                        v-model="objSearch.Name"
                        label="Nombre / Descripción"
                        outlined
                        dense
                        placeholder="Buscar por nombre o descripción"
                        hide-details
                      ></v-text-field>
                    </v-col>

                    <!-- Estado -->
                    <v-col
                      v-if="isStatusSelected"
                      cols="12"
                    >
                      <v-select
                        v-model="objSearch.Status"
                        :items="status"
                        item-value="id"
                        item-text="name"
                        label="Estado"
                        dense
                        outlined
                        return-object
                      >
                      </v-select>
                    </v-col>

                    <!-- Fecha de creación -->
                    <v-col
                      v-if="isDateSelected"
                      cols="12"
                    >
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="objSearch.ProgramDate"
                            label="Fecha del Programa"
                            placeholder="Fecha de creación del Programa"
                            persistent-hint
                            :prepend-inner-icon="icons.mdiCalendar"
                            outlined
                            dense
                            readonly
                            hide-details
                            v-bind="attrs"
                            v-on="on"
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="objSearch.ProgramDate"
                          no-title
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <!-- Fecha de Inicio -->
                    <v-col
                      v-if="isRangeDateSelected"
                      cols="12"
                      md="6"
                    >
                      <v-menu
                        v-model="menu3"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="objSearch.ProgramDateStart"
                            label="Fecha inicio"
                            placeholder="Fecha inicio"
                            persistent-hint
                            :prepend-inner-icon="icons.mdiCalendar"
                            outlined
                            dense
                            readonly
                            hide-details
                            v-bind="attrs"
                            v-on="on"
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="objSearch.ProgramDateStart"
                          no-title
                          @input="menu3 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <!-- Fecha de Fin -->
                    <v-col
                      v-if="isRangeDateSelected"
                      cols="12"
                      md="6"
                    >
                      <v-menu
                        v-model="menu4"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="objSearch.ProgramDateEnd"
                            label="Fecha fin"
                            placeholder="Fecha fin"
                            persistent-hint
                            :prepend-inner-icon="icons.mdiCalendar"
                            outlined
                            dense
                            readonly
                            hide-details
                            v-bind="attrs"
                            v-on="on"
                          >
                          </v-text-field>
                        </template>
                        <v-date-picker
                          v-model="objSearch.ProgramDateEnd"
                          no-title
                          @input="menu4 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <!-- Categoria -->
                    <v-col
                      v-if="isCategoryNameSelected"
                      cols="12"
                      md="6"
                    >
                      <v-select
                        v-model="objSearch.CategoryName"
                        :items="listaCategorias"
                        item-value="id"
                        item-text="name"
                        label="Categorías"
                        dense
                        outlined
                        return-object
                        @change="getSubCategorys(objSearch.CategoryName)"
                      >
                      </v-select>
                    </v-col>

                    <!-- SubCategoria -->
                    <v-col
                      v-if="isCategoryNameSelected"
                      cols="12"
                      md="6"
                    >
                      <v-select
                        v-model="objSearch.SubCategoryName"
                        :items="listaSubCategorias"
                        item-value="subCategoryId"
                        item-text="subCategoryName"
                        label="Sub Categorías"
                        dense
                        outlined
                        return-object
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-btn
                    type="reset"
                    outlined
                    class="mx-2"
                    @click="closeDialogSearchProgram"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    color="primary"
                    @click="searchProgram"
                  >
                    Buscar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-divider
              class="mx-3"
              vertical
            ></v-divider>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :loading="cargandoTabla"
                  small
                  color="accent"
                  @click="closeDialogSearchProgram"
                  v-on="on"
                >
                  <v-icon color="primary">
                    {{ icons.mdiReload }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Recargar</span>
            </v-tooltip>

            <v-divider
              class="mx-3"
              vertical
            ></v-divider>
            <v-btn
              v-if="haveAdd"
              :loading="cargando"
              small
              color="primary"
              @click="abrirForm"
            >
              Nuevo
            </v-btn>
          </v-card-title>
        </v-card>
      </template>
      <template v-slot:[`item.imageUrl`]="{ item }">
        <v-img
          contain
          max-height="40"
          max-width="40"
          :src="item.imageUrl"
          class="me-3"
        ></v-img>
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <div class="truncate_name">
          {{ item.name }}
        </div>
      </template>
      <template v-slot:[`item.description`]="{ item }">
        <div class="truncate">
          {{ item.description }}
        </div>
      </template>
      <template v-slot:[`item.modality`]="{ item }">
        <span v-if="item.modality.toLowerCase() === 'mixed'"> Presencial y Virtual </span>
        <span v-if="item.modality.toLowerCase() === 'facetoface'"> Presencial </span>
        <span v-if="item.modality.toLowerCase() === 'virtual'"> Virtual </span>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip
          v-if="item.status === 'approved'"
          small
          class="ma-2 elevation-1"
          color="success"
          text-color="white"
        >
          Aprobado
        </v-chip>
        <v-chip
          v-if="item.status === 'pending'"
          small
          class="ma-2 elevation-1"
          color="primary"
          text-color="white"
        >
          Pendiente
        </v-chip>
        <v-chip
          v-if="item.status === 'denied'"
          small
          class="ma-2 elevation-1"
          color="error"
          text-color="white"
        >
          Denegado
        </v-chip>
        <v-chip
          v-if="item.status === 'canceled'"
          small
          class="ma-2 elevation-1"
          color="error"
          text-color="white"
        >
          Cancelado
        </v-chip>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div style="float: left">
          <!-- Duplicar -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :loading="cargandoDelete"
                class="ma-1"
                x-small
                color="default"
                aria-placeholder="Duplicar"
                @click="doubleItem(item)"
                v-on="on"
              >
                <v-icon small>
                  {{ icons.mdiContentCopy }}
                </v-icon>
              </v-btn>
            </template>
            <span>Duplicar</span>
          </v-tooltip>
          <!-- Editar -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="haveEdit(item)"
                :loading="cargandoEditar"
                class="ma-1"
                x-small
                color="primary"
                @click="editItem(item)"
                v-on="on"
              >
                <v-icon small>
                  {{ icons.mdiPencil }}
                </v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <!-- Denegar -->
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="haveDelete"
                :loading="cargandoDelete"
                class="ma-1"
                x-small
                color="warning"
                @click="deniedItem(item)"
                v-on="on"
              >
                <v-icon small>
                  {{ icons.mdiCancel }}
                </v-icon>
              </v-btn>
            </template>
            <span>Denegar</span>
          </v-tooltip>
          <!-- Eliminar -->
          <span v-if="item.status.toLowerCase() !== 'approved'">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="haveDelete"
                  :loading="cargandoDelete"
                  class="ma-1"
                  x-small
                  color="error"
                  @click="deleteItem(item)"
                  v-on="on"
                >
                  <v-icon small>
                    {{ icons.mdiDelete }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Eliminar</span>
            </v-tooltip>
          </span>
        </div>
      </template>
      <template v-slot:no-data>
        <span> No hay registros en la tabla </span>
      </template>
    </v-data-table>
    <!-- Paginado -->
    <div
      v-if="lista.length > 0"
      class="d-flex align-center justify-space-between"
    >
      <v-pagination
        v-model="page"
        :circle="true"
        :length="longitudMaximaNum"
        :next-icon="icons.mdiArrowRight"
        :prev-icon="icons.mdiArrowLeft"
        :page="cantidadPaginas"
        :total-visible="totalVisible"
        @input="next"
      ></v-pagination>
      <span
        class="compact-form mt-0 mb-0 pt-0 pb-0"
        style="width: 140px"
      >
        <v-select
          v-model="registrosPorPaginaValue"
          :items="registrosPorPagina"
          placeholder="Cant. Registros"
          hide-details
          @change="onChangePagesCombo"
        ></v-select>
      </span>
    </div>
    <!-- Formulario de Confirmación -->
    <confirm ref="confirm"></confirm>
    <v-snackbar
      v-model="notificaciones.snackbar"
      :bottom="notificaciones.y === 'bottom'"
      :color="notificaciones.color"
      :left="notificaciones.x === 'left'"
      :multi-line="notificaciones.mode === 'multi-line'"
      :right="notificaciones.x === 'right'"
      :timeout="notificaciones.timeout"
      :top="notificaciones.y === 'top'"
      :vertical="notificaciones.mode === 'vertical'"
    >
      {{ notificaciones.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="notificaciones.snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {
  mdiPencil, mdiDelete, mdiArrowLeft,
  mdiArrowRight, mdiContentCopy,
  mdiFilter,
  mdiReload,
  mdiCancel,
} from '@mdi/js'
import { reactive, ref, computed } from '@vue/composition-api'
import moment from 'moment'
import HTTP from '@/utils/axios/axios-config-base'
import { useRouter } from '@/utils'
import Confirm from '@/components/Confirm.vue'

export default {
  components: {
    Confirm,
  },
  setup() {
    const { router, route } = useRouter()
    const tituloTabla = ref('Programa')
    const dialogSearch = ref(false)
    const confirm = ref(null)
    const cargandoTabla = ref(false)
    const cargando = ref(false)
    const cargandoEditar = ref(false)
    const cargandoDelete = ref(false)
    const headers = ref([
      {
        text: 'Nombre', align: 'start', value: 'name',
      },
      { text: 'Descripción', value: 'description' },
      { text: 'Estado', value: 'status' },
      { text: 'Modalidad', value: 'modality', width: '120' },
      { text: 'Fecha', value: 'programDate', width: '120' },
      { text: 'Hora', value: 'programHour' },
      { text: 'Fecha de Modificación', value: 'updateAt', width: '200' },
      {
        text: 'Acción',
        value: 'actions',
        align: 'center',
        width: '220',
        sortable: false,
      },
    ])
    const lista = ref([])
    const notificaciones = reactive({
      snackbar: false,
      color: '',
      mode: '',
      text: '',
      timeout: 3000,
      x: null,
      y: 'top',
    })

    // paginacion
    const page = ref(1)
    const longitudMaximaNum = ref(0)
    const cantidadPaginas = ref(5)
    const totalVisible = ref(5)
    const registrosPorPaginaValue = ref(50)
    const registrosPorPagina = ref([
      5, 10, 20, 50,
    ])
    const listaCategorias = reactive([])
    const listaSubCategorias = reactive([])
    const menu2 = ref(false)
    const menu3 = ref(false)
    const menu4 = ref(false)

    // Search
    const filterFiles = [
      { id: 'nombre_desc', name: 'Nombre / Descripción' },
      { id: 'fecha', name: 'Fecha' },
      { id: 'rango_fecha', name: 'Rango de Fechas' },
      { id: 'CategoryName', name: 'Categoría' },
      { id: 'Status', name: 'Estado' },
    ]
    const status = [
      { id: 'approved', name: 'Aprobado' },
      { id: 'pending', name: 'Pendiente' },
      { id: 'denied', name: 'Denegado' },
      { id: 'canceled', name: 'Cancelado' },
    ]
    const getInitialData = () => ({
      listfiles: [],
      LocationName: '',
      Name: '',
      Status: '',
      ProgramDate: '',
      ProgramDateStart: '',
      ProgramDateEnd: '',
      CategoryName: '',
      SubCategoryName: '',
    })
    const objSearch = reactive({
      listfiles: [],
      LocationName: '',
      Name: '',
      Status: '',
      ProgramDate: '',
      ProgramDateStart: '',
      ProgramDateEnd: '',
      CategoryName: '',
      SubCategoryName: '',
    })

    const permissions = localStorage.getItem('permissions')
    const haveDelete = computed(() => permissions.includes('Programs:Programs:FullAccess') || permissions.includes('Programs:Programs:Delete'))
    const haveAdd = computed(() => permissions.includes('Programs:Programs:FullAccess') || permissions.includes('Programs:Programs:CreateAccess'))
    const isAproved = computed(() => permissions.includes('Programs:Programs:FullAccess') || permissions.includes('Programs:Programs:ApproveAccess'))

    // const haveEdit = computed(() => permissions.includes('Programs:Programs:FullAccess') || permissions.includes('Programs:Programs:ReadEditAccess'))
    const haveEdit = item => {
      if ((permissions.includes('Programs:Programs:FullAccess') || permissions.includes('Programs:Programs:ApproveAccess')) || (permissions.includes('Programs:Programs:ReadEditAccess') && item.status !== 'approved')) return true

      return false
    }

    const cargarNotificaciones = async () => {
      if (route.value.params.notificaciones !== undefined) {
        notificaciones.color = route.value.params.notificaciones.color
        notificaciones.text = route.value.params.notificaciones.text
        notificaciones.snackbar = route.value.params.notificaciones.snackbar
      }
    }

    const listar = async (pagina = 1) => {
      if (pagina <= 0) {
        pagina = 1
      }

      lista.value = []
      cargandoTabla.value = true
      let search = ''
      if (objSearch.Name) {
        search += `&Name=${objSearch.Name}`
      }
      if (objSearch.ProgramDateStart && objSearch.ProgramDateEnd) {
        // 2022-03-21
        // search += `&EventDateStart=${objSearch.EventDateStart}`
        // search += `&EventDateEnd=${objSearch.EventDateEnd}`

        search += `&ProgramDateStart=${objSearch.ProgramDateStart}`
        search += `&ProgramDateEnd=${objSearch.ProgramDateEnd}`
      }
      if (objSearch.ProgramDate) {
        search += `&ProgramDate=${objSearch.ProgramDate}`
      }
      if (objSearch.Status) {
        search += `&Status=${objSearch.Status ? objSearch.Status.id : ''}`
      }
      if (objSearch.LocationName) {
        search += `&LocationName=${objSearch.LocationName}`
      }
      if (objSearch.CategoryName) {
        search += `&CategoryName=${objSearch.CategoryName ? objSearch.CategoryName.name : ''}`
      }
      if (objSearch.SubCategoryName) {
        search += `&SubCategoryName=${objSearch.SubCategoryName ? objSearch.SubCategoryName.subCategoryName : ''}`
      }

      let URL = ''
      if (isAproved.value) {
        URL = `programs-api/programs/managers?page=${page.value}&pageSize=${registrosPorPaginaValue.value}&ShowAll=${true}${search}`
      } else {
        URL = `programs-api/programs/managers?page=${page.value}&pageSize=${registrosPorPaginaValue.value}&ShowAll=${false}${search}`
      }

      // const URL = `${HTTP.defaults.baseURL}programs-api/programs/managers?page=${page.value}&pageSize=${registrosPorPaginaValue.value}${search}`
      const resp = await HTTP.get(URL)
      if (resp.data.value.entities.length > 0) {
        for (let index = 0; index < resp.data.value.entities.length; index += 1) {
          const obj = {
            ...resp.data.value.entities[index],
            programDate: moment(resp.data.value.entities[index].programDate).format('DD-MM-YYYY'),
            updateAt: moment(resp.data.value.entities[index].updateAt).format('DD-MM-YYYY HH:mm'),
          }
          lista.value.push(obj)
        }
        cargandoTabla.value = false

        // para evitar error de slint missin radix parameter
        // var num = parseInt("071", 10);  // 71
        longitudMaximaNum.value = parseInt((resp.data.value.pagination.total / registrosPorPaginaValue.value), 10)
        + (resp.data.value.pagination.total % registrosPorPaginaValue.value === 0 ? 0 : 1)
      } else {
        cargandoTabla.value = false
      }
    }

    const isNameSelected = computed(() => objSearch.listfiles.includes('nombre_desc'))
    const isDateSelected = computed(() => objSearch.listfiles.includes('fecha'))
    const isRangeDateSelected = computed(() => objSearch.listfiles.includes('rango_fecha'))
    const isCategoryNameSelected = computed(() => objSearch.listfiles.includes('CategoryName'))
    const isSubCategoryNameSelected = computed(() => objSearch.listfiles.includes('SubCategoryName'))
    const isStatusSelected = computed(() => objSearch.listfiles.includes('Status'))
    const isLocationNameSelected = computed(() => objSearch.listfiles.includes('LocationName'))

    const getCategorys = async () => {
      const URL = `${HTTP.defaults.baseURL}/programs-api/program-categories`
      const resp = await HTTP.get(URL)
      if (resp.status === 200) {
        for (let index = 0; index < resp.data.value.entities.length; index += 1) {
          listaCategorias.push(resp.data.value.entities[index])
        }
      }
    }
    const getSubCategorys = objCategoria => {
      listaSubCategorias.splice(0, listaSubCategorias.length)
      if (objCategoria) {
        for (let index = 0; index < objCategoria.subCategories.length; index += 1) {
          listaSubCategorias.push(objCategoria.subCategories[index])
        }
      }
    }

    const searchProgram = async () => {
      await listar(page.value)

      Object.assign(objSearch, getInitialData())
      dialogSearch.value = false
    }
    const closeDialogSearchProgram = async () => {
      Object.assign(objSearch, getInitialData())
      listar(page.value)
      dialogSearch.value = false
    }
    cargarNotificaciones()
    listar(page.value)
    getCategorys()

    return {
      lista,
      headers,
      router,
      tituloTabla,
      confirm,
      notificaciones,

      // loadin
      cargando,
      cargandoTabla,
      cargandoEditar,
      cargandoDelete,

      // icons
      icons: {
        mdiPencil,
        mdiDelete,
        mdiArrowLeft,
        mdiArrowRight,
        mdiContentCopy,
        mdiFilter,
        mdiReload,
        mdiCancel,
      },

      // paginacion
      page,
      longitudMaximaNum,
      cantidadPaginas,
      totalVisible,
      registrosPorPaginaValue,
      registrosPorPagina,

      haveDelete,
      haveEdit,
      haveAdd,
      isAproved,

      // Menu Search
      listaCategorias,
      listaSubCategorias,
      dialogSearch,
      filterFiles,
      objSearch,
      searchProgram,
      menu2,
      menu3,
      menu4,
      status,

      isNameSelected,
      isDateSelected,
      isRangeDateSelected,
      isCategoryNameSelected,
      isSubCategoryNameSelected,
      isStatusSelected,
      isLocationNameSelected,

      // Metodos
      cargarNotificaciones,
      listar,
      closeDialogSearchProgram,
      getCategorys,
      getSubCategorys,

      abrirForm: () => {
        cargando.value = true
        setTimeout(() => {
          router.push({ name: 'crear_programas' })
          cargando.value = false
        }, 500)
      },

      onChangePagesCombo: () => {
        listar(page.value)
      },
      next: () => {
        listar(page.value)
      },

      editItem: item => {
        const index = lista.value.indexOf(item)
        router.push({ name: 'editar_programas', params: { id: lista.value[index].programId } })
      },

      doubleItem: async item => {
        const index = lista.value.indexOf(item)
        router.push({ name: 'duplicar_programas', params: { id: lista.value[index].programId } })
      },

      deniedItem: async item => {
        const index = lista.value.indexOf(item)
        confirm.value
          .open(
            'Denegar programa',
            '¿Está seguro que desea denegar el programa seleccionado?',
            {
              color: 'primary',
              width: '600',
              activarComentario: true,
              comment: '',
            },
          )
          .then(confirm1 => {
            if (confirm1) {
              const URL = `${HTTP.defaults.baseURL}programs-api/programs/${
                lista.value[index].programId}`
              const resp = HTTP.patch(URL, { status: 'denied', comment: confirm.value.options.comment })
              resp.then(val => {
                if (val.status === 200) {
                  notificaciones.color = 'success'
                  notificaciones.text = 'El registro se ha denegado satisfactoriamente'
                  notificaciones.snackbar = true
                  listar()
                }
              })
            } else {
              notificaciones.color = 'info'
              notificaciones.text = 'Operación cancelada'
              notificaciones.snackbar = true
            }
          })
      },

      deleteItem: async item => {
        const index = lista.value.indexOf(item)
        confirm.value
          .open(
            'Eliminar Programa',
            '¿Está seguro que desea eliminar el programa seleccionado?',
            {
              color: 'primary',
            },
          )
          .then(confirm => {
            if (confirm) {
              const URL = `${HTTP.defaults.baseURL}programs-api/programs/${
                lista.value[index].programId}`
              const resp = HTTP.delete(URL)
              resp.then(val => {
                if (val.status === 200) {
                  notificaciones.color = 'success'
                  notificaciones.text = 'El registro se ha eliminado satisfactoriamente'
                  notificaciones.snackbar = true
                  listar()
                } else {
                  notificaciones.color = 'error'
                  notificaciones.text = 'Ha ocurrido un error mientras se elminaba el registro'
                  notificaciones.snackbar = true
                }
              })
            }
          })
      },

      remove: async item => {
        const index = objSearch.listfiles.indexOf(item.id)
        if (index >= 0) objSearch.listfiles.splice(index, 1)
      },
    }
  },
}
</script>

<style>
  .truncate_name {
      max-width: 12vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }
  .truncate {
      max-width: 20vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }
</style>
